<template>
  <div>
    <div class="hero-wrapper">
      <div class="hero">
        <div class="container">
          <h1 class="text-white">Statistics</h1>
          <h3 class="text-white">Overview of your commissions.</h3>
        </div>
      </div>
    </div>
    <Header/>

    <div class="container mt-24">

      <div class="section-title ">
        <div class="d-flex pt-2 pl-2 pr-2 justify-content-between">

          <h4 class="p-3 mb-1">
            <span>{{formData.period}} <small v-if="introducer_admin && !formData.introducer">({{'All of your users'}})</small><small v-else-if="introducer_admin && formData.introducer_name ">({{formData.introducer_name}})</small></span>
          </h4>
          <h4 class="p-3 configGear">
            <b-link @click="config">
              <i class="text-25 text-secondary i-Gear"></i>
            </b-link>
          </h4>
        </div>
      </div>


      <Inforgraphics :formData="formData" />

      <b-row class="mb-24">
        <b-col lg="6" md="6" sm="12">
          <CommissionPieChart :formData="formData" />
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <TransactionPieChart :formData="formData" />
        </b-col>
      </b-row>

      <b-row class="mb-24">
        <b-col>
          <TrendChart :formData="formData" />
        </b-col>
      </b-row>

    </div>


    <div class="container">
      <Footer />
    </div>

    <ConfigModal v-model="show.configModal" :isIntroducerAdmin="show.isIntroducerAdmin" :formData="formData" @change="set" />

  </div>
</template>

<script>
import Footer from "@/components/common/questionnaires/questionnaire/Footer";
import Inforgraphics from "@/views/introducer/statistics/Infographics";
import CommissionPieChart from "@/views/introducer/statistics/CommissionPieChart";
import TransactionPieChart from "@/views/introducer/statistics/TransactionPieChart";
import TrendChart from "@/views/introducer/statistics/TrendChart";
import ConfigModal from "@/views/introducer/statistics/ConfigModal";


export default {
  name: 'Statistics',
  components: {
    Footer,
    Inforgraphics,
    CommissionPieChart,
    TransactionPieChart,
    TrendChart,
    ConfigModal,
  },
  watch:{
    user: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.formData.introducer = null
          this.formData.introducer_name = null
          this.show.isIntroducerAdmin = !!this.user?.is_introducer_admin
        }
      }
    },
  },
  data() {
    return {
      show:{
        configModal: false,
        isIntroducerAdmin: !!this.user?.is_introducer_admin
      },
      formData:{
        period: 'Last 6 Months',
        introducer: null,
        introducer_name: null,
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    introducer() {
      return this.user?.is_introducer
    },
    introducer_admin() {
      return !!this.user?.is_introducer_admin
    }
  },
  methods: {
    config(){
      this.show.configModal=true
    },
    set(val){
      this.formData = val
    },
  }
}
</script>

<style scoped>
.configGear{
  z-index: 999; 
  background-color: #f5f7fa;
  margin-bottom: -12px;
}
</style>