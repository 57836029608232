<template>
  <b-card>
    <h3 class="ul-widget__head-title">Commissions</h3>

    <b-card-body>
      <apexchart :options="chartOptions" :series="series" height="300" />
    </b-card-body>
  </b-card>
</template>

<script>
import { http } from "@/services";

import "@/plugins/apexChart.js";
import moment from "moment";

export default {
  name: "TrendChart",
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      series: [],
    };
  },
  mounted() {
    this.fetch();
  },
  watch: {
    formData() {
      this.fetch();
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    introducer() {
      return this.user?.is_introducer;
    },
    chartOptions() {
      return {
        chart: {
          toolbar: {
            show: false,
          },
          type: "bar",
        },
        colors:['#663069'],
        dataLabels: {
          formatter: function (value) {
            return "£" + value.toFixed(2);
          },
          style: {
              fontSize: "12px",
              fontFamily: 'Montserrat',
            }
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return `£${value}`;
            },
          },
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
          },
        },
        xaxis: {
          labels: {
            style: {
              fontSize: "12px",
              fontFamily: "Montserrat",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "12px",
              fontFamily: "Montserrat",
            },
            formatter: (val) => {
              return `£${val.toFixed(0)}`;
            },
          },
        },
      };
    },
  },
  methods: {
    fetch() {
      // fetches results
      http
        .get("report_commissions_trend_chart", { params: this.formData })
        .then((response) => {
          this.series = [
            {
              name: "Commissions",
              data: response.data.map((d) => ({
                x: moment(d.date).format("MMM"),
                y: d.total,
              })),
            },
          ];
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
