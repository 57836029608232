<template>

  <b-card>
    <h3 class="ul-widget__head-title">
        Transactions
        </h3>
  
    <b-card-body>
  
      <apexchart :options="chartOptions"
                     :series="series"
                     height="300"
            />
  
    </b-card-body>
  
  </b-card>
  
  
  </template>
  
  <script>
  import {http} from "@/services";
  import "@/plugins/apexChart.js";
  
  export default {
    name: 'TransactionPieChart',
    props:{
      formData:{
        type:Object,
        required:true
      }
    },
    data() {
      return {
        series: [],
        labels:[],
      }
    },
    mounted() {
      this.fetch()
    },
    watch:{
      formData(){
        this.fetch()
      },
    },
    computed: {
      user() {
        return this.$store.getters.user
      },
      chartOptions(){
        return {
          chart: {
            toolbar: {
              show: false,
            },
            type: 'pie',
          },
          colors:['#663069','#a58e50','#447694'],
          labels: this.labels,
          legend: {
            show: true,
            position: 'bottom',
            fontSize: "12px",
            fontFamily: 'Montserrat',
          },
          plotOptions:{
            pie:{
              dataLabels:{
                offset:-30
              },
            },
          },
          dataLabels:{
            formatter: function(value, { seriesIndex, w }) {
              return ["%"+value.toFixed(0),w.config.labels[seriesIndex] + ":  " + w.config.series[seriesIndex]]
            },          
            style: {
              fontSize: "12px",
              fontFamily: 'Montserrat',
            }
          },
          tooltip:{
            style: {
              fontSize: "12px",
              fontFamily: 'Montserrat',
            }
          }
        }
      },
    },
    methods: {
      fetch() {
        // fetches results
        http.get('report_transactions_chart', {params: this.formData}).then(
            response => {
              this.chartdata = response.data
              console.log('this.chartdata', this.chartdata)
              this.labels = Object.keys(this.chartdata)
              this.series = []
              this.labels.forEach(d=>{
                this.series.push(this.chartdata[d]?this.chartdata[d]:0)
              })
            }
        ).catch(
            error => {
              console.log(error)
            }
        )
      },
    }
  }
  </script>
  