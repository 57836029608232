<template>
      <b-row v-if="info">

<!-- total commission -->
<b-col lg="3" md="6" sm="12">
  <b-card
    class="card-icon-bg card-icon-bg-primary o-hidden mb-30 text-center"
  >
    <i class="i-Financial"></i>
    <div class="content">
      <p class="text-muted mt-2 mb-0">Commissions</p>
      <p class="text-primary text-24 line-height-1 mb-2">£{{info.commissions||0}}</p>
    </div>
  </b-card>
</b-col>

<!-- clients -->
<b-col lg="3" md="6" sm="12">
  <b-card
    class="card-icon-bg card-icon-bg-primary o-hidden mb-30 text-center"
  >
    <i class="i-Business-ManWoman"></i>
    <div class="content">
      <p class="text-muted mt-2 mb-0">Clients</p>
      <p class="text-primary text-24 line-height-1 mb-2">{{info.clients||0}}</p>
    </div>
  </b-card>
</b-col>

<!-- cooupons -->
<b-col lg="3" md="6" sm="12">
  <b-card
    class="card-icon-bg card-icon-bg-primary o-hidden mb-30 text-center"
  >
    <i class="i-Ticket"></i>
    <div class="content">
      <p class="text-muted mt-2 mb-0">Coupons</p>
      <p class="text-primary text-24 line-height-1 mb-2">{{info.coupons||0}}</p>
    </div>
  </b-card>
</b-col>

<!-- products -->
<b-col lg="3" md="6" sm="12">
  <b-card
    class="card-icon-bg card-icon-bg-primary o-hidden mb-30 text-center"
  >
    <i class="i-Checkout"></i>
    <div class="content">
      <p class="text-muted mt-2 mb-0">Products</p>
      <p class="text-primary text-24 line-height-1 mb-2">{{info.products||0}}</p>
    </div>
  </b-card>
</b-col>
</b-row>

</template>

<script>

import {http} from "@/services";

export default {
  name: 'Infographics',
  props:['formData'],
  data() {
    return {
      info: null,
      show:{
        loading:false,
      }
    }
  },
  mounted() {
    this.fetch()
  },
  watch:{
    formData(){
      this.fetch()
    },
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    introducer() {
      return this.user?.is_introducer
    }
  },
  methods: {
    fetch() {
      // fetches results
      this.show.loading = true

      http.get('report_infographics', {params: this.formData}).then(
          response => {
            this.info = response.data
            this.show.loading = false
          }
      ).catch(
          error => {
            console.log(error)
            this.show.loading = false
          }
      )
    },

  }
}
</script>
